import * as React from "react";
import Fetcher from "./Fetch";
import moment from "moment";
import {useState} from "react";
import Signature from "../Profile/Details/Signature";

declare var cadesplugin: any;
declare var checkPlugin: any;
declare var canAsync: any;
declare var PrepareCertSelector: any;
declare var CADESCOMCryptoProviderAsync: any;
declare var CADESCOMCryptoProvider: any;

function LoginWithCert() {
    const [error, setError] = useState(null);
    
    const selectCert = () => {
        cadesplugin.then(function () {
            checkPlugin(function (isPluginWorking, version) {
                if (isPluginWorking) {
                    let signer = null;

                    if (canAsync()) {
                        signer = new CADESCOMCryptoProviderAsync({ showErrors: true, detached: false });

                    }
                    else {
                        signer = new CADESCOMCryptoProvider({ showErrors: true, detached: false });
                    }

                    signer.selectCertificate(PrepareCertSelector, function (hash) {
                        signer.preferredThumbprint = hash;
                        signer.checkLocal(false, function (result) {
                            if (result) {
                            }
                            else {
                                setError('Некорректный сертификат');
                            }
                        });
                    });
                }
            });
        });
    };
    
    return(<>
           <Signature isLoginForm={ true } certName='' certDate='' />
    </>)
}

export default LoginWithCert;