import { parse } from "path";
import { LotMassActions, LotActions } from "./types";

export const ProcessSortParams = (sort) => {
    let orderBy = "Id";
    let asc = false;
    switch (sort) {
        case "price_asc":
            orderBy = "Price";
            break;
        case "price_desc":
            orderBy = "Price";
            asc = false;
            break;
        case "start_date_asc":
            orderBy = "StartDate";
            break;
    }

    return { OrderBy: orderBy, Ascending: asc }

}

export const GetSortOption = (orderBy, asc) => {
    let sort = "";

    switch (orderBy) {
        case "Price":
            sort = "price";
            break;
        case "StartDate":
            sort = "start_date";
            break;
        default:
            sort = "id";

    }

    return sort + (asc ? "_asc" : "_desc");

}

export const ParseStringToNullableInt = (str) => {
    let parsed = parseInt(str);

    return isNaN(parsed) ? null : parsed;
}

export const ShowDelayedNotification = (str, isError = false) => {
    window["showDelayedNotification"](str, isError);
}

export const GetEndpointMassAction = (action: LotMassActions) => {
    switch (action) {
        case LotMassActions.ApplyLot:
            return "/TradeLotActionsApi/MassApplyLots";
        case LotMassActions.DeleteLot:
            return "/TradeLotActionsApi/MassDeleteLots";
        case LotMassActions.FinishTrade:
            return "/TradeLotActionsApi/MassFinishLots";
        default:
            return null;

    }


}

export const GetEndpointAction = (action: LotActions) => {
    switch (action) {
        case LotActions.CopyLot:
            return "/TradeLotActionsApi/CopyLot";
        case LotActions.CopyLotWOPictures:
            return "/TradeLotActionsApi/CopyLot";
        case LotActions.DeleteLot:
            return "/TradeLotActionsApi/DeleteLot";
        case LotActions.FinishTrade:
            return "/TradeLotActionsApi/FinishLot";
        default:
            return null;

    }


}

export const GroupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};