import Fetcher from "./Common/Fetch";
import { SettingsData } from "./settingsTypes";

export const DEFAULT_LOT_IMAGE = "/Content/images/defaultLotImage.png"; 
export const LOT_DETAILS_URL = "/TradeLot/View/";
export const COMPANY_LOTS_URL = "/Category/Details?companyId=";
export const DATE_TIME_FORMAT = "DD.MM.YYYY в HH:mm";

class Settings {
    constructor() { }

    Data: SettingsData

    IsLoaded: false

    Init() {

        if (!this.IsLoaded)
            Fetcher('/Settings/GetSettings/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.Data = data;
                    this.IsLoaded = true;
                });
    }

}
export default new Settings();